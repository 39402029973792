require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();
require("channels");
require("cocoon-js");

import 'bootstrap';
import 'admin-lte';
import '@fortawesome/fontawesome-free/js/all';
import 'daterangepicker';
import 'bootstrap-autocomplete';
import 'bs-custom-file-input';
import 'moment';

// JSs
import '../src/date_time_range_picker';
import '../src/suggest';
import '../src/select_sync_room';
import '../src/select_sync_dealership_branch';
import '../src/dealer_invoice_limit_selectable_month';
import '../src/bs_custom_file_input';
import '../src/jpostal';
import '../src/sidebar_menu';
import '../src/contract_start_date';
import '../src/inquiry_details_template';
import '../src/service_fee';

// CSSs
import '../stylesheets/application';
