;(() => {
  const init = () => {
    var url = window.location;

    $('ul.nav-sidebar a').filter(function() {
      return url.href.includes(this.href);
    }).addClass('active');

    $('ul.nav-sidebar a.href-new').filter(function() {
      if(url.href == this.href) $('ul.nav-sidebar a.href-index').removeClass('active');
    })

    $('ul.nav-treeview a').filter(function() {
      return url.href.includes(this.href);
    }).parentsUntil(".nav-sidebar > .nav-treeview").addClass('menu-open').prev('a').addClass('active');
  };

  $(init);

})();
