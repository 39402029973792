;(() => {
  const init = () => {
    limitSelectableMonth();

    function limitSelectableMonth() {
      var date = new Date($('select[id*=_2i]').data('currentDate'));

      $monthSelect = $('select[id*=_2i]');
      $yearSelect = $("select[id*=_1i]");

      var current_year = date.getFullYear();
      var current_month = date.getMonth() +1; // 0 index
      var default_selected_month = $monthSelect.val(); // depends on Rails rendering at init  or pass data-attr

      // init
      // switch by year option element number
      switch ($yearSelect.find('option').length) {
        case 1:
          // remove except data of currentDate
          $monthSelect.find('option').each(function (n) {
            // current or last month
            removeMonthExcept(current_month, parseInt(current_month) - 1);
          });
          break;
        case 2:
          updateMonthOption(current_year);
          $yearSelect.change(function (n) {
            updateMonthOption(this.value);
          });
          break;
      }

      // update month by year selected
      function updateMonthOption(current_year) {
        // years sorted
        var years_arr = Array.from($yearSelect.find('option')).map(function (n) {
          return n.value
        }).sort();

        // if bigger year
        if (current_year === years_arr.pop()) {
          resetMonthOptions();
          removeMonthExcept(1);
        } else {
          resetMonthOptions();
          removeMonthExcept(12);
        }
      }

      // only leave specific value option
      function removeMonthExcept(months) {
        var args = arguments;
        $monthSelect.find('option').each(function (i, n) {
          var match = false;
          for (var i = 0; i < args.length; i++) {
            // check if arguments value match option value
            if (this.value === String(args[i])) match = true;
          }
          if (match) {
            if (this.value === default_selected_month) $(this).prop('selected', true);
          } else {
            $(this).remove();
          }
        });
      }

      // reset/refill month options
      function resetMonthOptions() {
        $monthSelect.empty();
        for (i = 1; i <= 12; i++) {
          $monthSelect.append($('<option>', {value: i, text: i}));
        }
      }
    }
  };

  // URL dealer/invoice_datas を含む場合のみ実行
  if (window.location.pathname.match(/\/dealer\/invoice_datas/)) {
    $(init);
  }
})();
