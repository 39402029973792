import axios from 'axios';

;(() => {
  // selectors
  const target_selector = '#contract_dealership_id';
  const selectors_selector = 'select[id^=contract_contract_dealership_representatives_attributes][id$=_dealership_representative_id]';
  const add_button_selector = 'a.add_fields[data-association=contract_dealership_representative]';
  const remove_button_selector = 'a.remove_fields';


  const init = () => {
    const $selector = $(target_selector);
    const $container_selector = $selector.parent().parent(); // or '.dealership_fields'

    // hide add button & disable select on init
    // hide remove button of first
    $container_selector.find(add_button_selector).hide();
    $container_selector.find(remove_button_selector).first().hide();

    // find select including others rather than cocoon
    $container_selector.find('select').each((key, el) => {
      const $select = $(el);
      // if already value is set, like after Post once
      if ($selector.val() === '') {
        // if needs default value
        // const options = [$('<option>').attr('value', '').text('代理店名を選択ください')];
        // $select.html(options);

        $select.prop('disabled', true);
      }
    });

    checkAddButton();
    // text field of Bootstrap Autocomplete
    // event by Bootstrap Autocomplete
    $selector.on('autocomplete.select', async (event, selectedItem) => {
      // checkAddButton();
      afterAutocomplete($selector, selectedItem);
    });

    // when click & add element with cocoon, prepare new select
    $(add_button_selector).on('click', function(event) {

      // coccon's callback is not fired, so use this..
      setTimeout(function(e){
        // copy html of select
        const $new_select = $container_selector.find('select').last();
        $new_select.html($container_selector.find(selectors_selector).first().html());
        $new_select.val('');
        checkAddButton();

        // after remove button got created
        // when click & remove element with cocoon, prepare add button
        setAfterRemoveButton();
      }, 300);
    });
    if ($(remove_button_selector).length > 0) setAfterRemoveButton();

  };

  function checkAddButton(){
    const $selects = $(selectors_selector);
    // hide add button if not enough candidate
    if ($selects.first().children().filter(item => item).length - $selects.filter(function(){ return this.parentElement.parentElement.style.display != 'none' }).length <= 0) {
      $(add_button_selector).hide();
    } else {
      $(add_button_selector).show();
    }
  }

  function setAfterRemoveButton(){
    $(remove_button_selector).on('click', function (event) {
      // coccon's callback is not fired, so use this..
      setTimeout(function (e) {
        checkAddButton();
      }, 300);
    });

  }

  function afterAutocomplete(elem, selectedItem) {
    const $elem = $(elem);

    // find select tag
    $elem.parent().parent().find('select').each(async (key, el) => {
      const $select = $(el);
      const options = [$('<option>').attr('value', '').text('選択してください')];
      const url = decodeURI($select.data('refer')).replace('{{value}}', selectedItem.value);

      let arr;
      await axios.get(url)
          .then(function (response) {
            arr = response.data
          }).finally(function () {
            $select.prop('disabled', false)
          })
          .catch (function (error) {
            console.error(error);
          });
      if (arr == null || arr.length === 0) return false;

      // add to options
      $.each(arr, (key, obj) => {
        const $option = $('<option>').attr('value', obj.value).text(obj.text);
        if (obj.value === $select.val()) $option.prop('selected', true);
        options.push($option);
      });
      $select.html(options);
      checkAddButton();
    });
  }

  // URL edit/new のみ実行
  if (window.location.pathname.match(/\/contracts/)) {
    $(init);
  }
})();
