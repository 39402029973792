;(() => {
  const selectors = {
    serviceFeeInput: '.js-service-fee-input',
    dealershipIdInput: '.js-dealership-id-input',
    serviceTypeInput: '.js-service-type-input',
  };

  const init = () => {
    const { serviceFeeInput } = selectors;

    if ($(serviceFeeInput).length <= 0) {
      return;
    }
    setEventListener()
  };

  const setEventListener = () => {
    const { dealershipIdInput, serviceTypeInput } = selectors;

    $(document).on('autocomplete.select', dealershipIdInput, (event) => {
      renderServiceFee();
    });

    $(document).on('change', serviceTypeInput, (event) => {
      renderServiceFee();
    });
  };

  const renderServiceFee = () => {
    const { dealershipIdInput, serviceTypeInput, serviceFeeInput } = selectors;
    const dealershipIdInputName = $(dealershipIdInput).attr('name').replace('_text', '');
    const dealershipId = $(`input[name = '${ dealershipIdInputName }']`).val();
    const serviceType = $(`${ serviceTypeInput } option:selected`).val();

    if (!!dealershipId && !!serviceType) {
      fetchServiceFee(dealershipId, serviceType).done((response) => {
        $(serviceFeeInput).val(response.amount);
      });
    }
  };

  const fetchServiceFee = (dealershipId, serviceType) => {
    return ($.ajax(`/api/dealerships/${dealershipId}/service_fee`,
      {
        type: 'GET',
        dataType: 'json',
        data: { service_type: serviceType }
      }
    ));
  };

  $(init);

})();
