// DateTimeRangePicker
;(() => {
  const expr = '.js-datetimerangepicker';
  const fromExpr = '.js-datetimerangepicker-from';
  const toExpr = '.js-datetimerangepicker-to';
  const dateFormat = 'YYYY-MM-DD';

  const init = () => {
    const $elements = $(expr);
    if (!$elements.length) {
      return;
    }
    $elements.each((key, element) => {
      const $element = $(element);
      const $fromExpr = $element.siblings(fromExpr);
      const $toExpr = $element.siblings(toExpr);

      $element.daterangepicker({
        opens: 'left',
        autoUpdateInput: false,
        showCustomRangeLabel: false,
        timePicker24Hour: true,
        locale: {
          format: dateFormat,
          separator: ' - ',
          fromLabel: '開始',
          toLabel: '終了',
          weekLabel: '週',
          daysOfWeek: [
            '日',
            '月',
            '火',
            '水',
            '木',
            '金',
            '土'
          ],
          monthNames: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ],
          firstDay: 1,
          cancelLabel: 'クリア',
          applyLabel: '適用'
        }
      });

      $element.on('apply.daterangepicker', function(e, picker) {
        $element.val(picker.startDate.format(dateFormat) + ' - ' + picker.endDate.format(dateFormat));
        $fromExpr.val(picker.startDate.format(dateFormat));
        $toExpr.val(picker.endDate.format(dateFormat));
      });

      $element.on('cancel.daterangepicker', function(e, picker) {
        $element.val('');
        $fromExpr.val('');
        $toExpr.val('');
      });

      if ($fromExpr.val() == '' && $toExpr.val() == '') {
        $element.data('daterangepicker').setStartDate(moment().subtract(30, 'days').format(dateFormat));
        $element.data('daterangepicker').setEndDate(moment().format(dateFormat));

        if ($element.data('is-initial-empty')) {
          $element.val(moment().subtract(30, 'days').format(dateFormat) + ' - ' + moment().format(dateFormat));
        }

        return
      } 

      $element.data('daterangepicker').setStartDate(moment($fromExpr.val(), dateFormat));
      $element.data('daterangepicker').setEndDate(moment($toExpr.val(), dateFormat));
      $fromExpr.val(moment($fromExpr.val(), dateFormat).format(dateFormat));
      $toExpr.val(moment($toExpr.val(), dateFormat).format(dateFormat));
      $element.val(moment($fromExpr.val(), dateFormat).format(dateFormat) + ' - ' + moment($toExpr.val(), dateFormat).format(dateFormat));
    });
  };

  $(init);

})();
