;(() => {
  const expr = '.js-suggest';
  const init = () => {
    const $elements = $(expr);
    if (!$elements.length) {
      return;
    }
    $elements.each((key, element) => {
      const $element = $(element);
      $element.autoComplete({
        minLength: 1,
        resolverSettings: {
          url: $element.data('refer')
        }
      });
    });
  };
  $(init);
})();
