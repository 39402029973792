;(() => {
  const init = () => {
    $('#respond_to_your_inquiry').click(function() {
      $('#correspond_thread_body').val(function(i, text) {
        text += '入電者：入居者様　　　受付：--\n';
        text += '内容：詳細を簡潔に入力\n';
        text += '訪問日：確定次第、報告致します。or●月●日●時～●時訪問いたします。\n';

        return text;
      });
    });

    $('#not_responding_to_your_inquiry').click(function() {
      $('#correspond_thread_body').val(function(i, text) {
        text += '入電者：入居者様　　　受付：--\n';
        text += '内容：詳細を簡潔に入力\n';
        text += '申し訳ございません、今回のトラブルはサービス対象外の為、訪問出来かねます。\n';
        text += '※サービス対象外でも、有償対応が可能な場合もございます。ご要望の際はお問い合わせ下さい。\n';
        text += '（原則訪問点検のみ、8,000円＋消費税が発生いたします。）\n';

        return text;
      });
    });

    $('#schedule_your_visit').click(function() {
      $('#correspond_thread_body').val(function(i, text) {
        text += '訪問日程、以下に確定いたしました。\n';
        text += '--/--　　　--時から--時の間\n';
        text += '対応進捗あり次第、報告致します。\n';

        return text;
      });
    });

    $('#report_on_your_progress').click(function() {
      $('#correspond_thread_body').val(function(i, text) {
        text += '対応進捗報告\n';
        text += '結果：改善されました／改善されず／点検のみの対応／再訪問必要\n';
        text += '原因：\n';
        text += '対応方法：\n';
        text += 'ステータス：完了といたします。移管といたします。対応中のままといたします。\n';

        return text;
      });
    });

    $('#report_estimate').click(function() {
      $('#correspond_thread_body').val(function(i, text) {
        text += '提案させて頂いた工事について、見積額を報告いたします。\n';
        text += '当社にご発注頂く際は御手数ですが、送信差上げたメールに全員返信にてご依頼いただきます様、お願い申し上げます。\n';
        text += '◆総額：　　円+税　円＝　円\n';
        text += '◆内訳（全て税別価格）\n';
        text += '・部材費　　：　円\n';
        text += '・出張工事費：　円\n';
        text += '・廃材処分費：　円\n';
        text += '・諸経費　　：　円\n';

        return text;
      });
    });
  };

  $(init);

})();