;(() => {
  const init = () => {
    $('#contract_contract_start_date').blur(function() {
      if (Date.parse($(this).val())) {
        $('#contract_end_date_modal').modal('show');
      };
    });

    $('#contract_end_date_button').click(function(){
      let contract_start_date = moment($('#contract_contract_start_date').val());
      let contract_end_date = contract_start_date.add(2, 'y').add(-1, 'days');
      $('#contract_contract_end_date').val(contract_end_date.format('YYYY-MM-DD'))
                                      .focus();
    });

    $('#no_contract_end_date_button').click(function(){
      $('#contract_contract_end_date').focus();
    });


    // URL edit/new のみ実行
    if (window.location.pathname.match(/\/edit$|\/new$/)) {
      function checkDisablePersonHimself() {
        const that = $('#contract_subscriber_attributes_relationship');
        if (that.val() === 'the_person_himself') {
          toggleInputDisabled(that, true);
        } else {
          toggleInputDisabled(that, false);
        }
      }
      function toggleInputDisabled(that, disabled) {
        $(that).parent().parent().find('input[type=text], select').each(function (index) {
          // 本人の場合、続柄以外の欄を無効化
          if ($(this).attr('id') != 'contract_subscriber_attributes_relationship') {
            $(this).attr("disabled", disabled)
          }
        });
      }
      checkDisablePersonHimself();

      $('#contract_subscriber_attributes_relationship').change(function () {
        checkDisablePersonHimself();
      });
    }
  };
  $(init);

})();
