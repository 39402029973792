require('jquery-jpostal-ja');

;(() => {
  const expr = '.js-jpostal';
  const postal = '.js-jpostal-postal';
  const prefecture = '.js-jpostal-prefecture';
  const address = '.js-jpostal-address';

  const init = () => {
    $('body').
      on('DOMNodeInserted', setup).
      trigger('DOMNodeInserted');
  };
  const setup = () => {
    const $elements = $(expr);
    if (!$elements.length) { return; }
    $elements.each((key, element) => {
      const $element = $(element);
      if ($element.prop('jpostalized')) { return true; }
      $element.prop('jpostalized', true);
      const $postal = $element.find(postal);
      const $prefecture = $element.find(prefecture);
      const $address = $element.find(address);
      $postal.jpostal({
        postcode: [
          `#${$postal.attr('id')}`,
        ],
        address: {
          [`#${$prefecture.attr('id')}`]: '%3',
          [`#${$address.attr('id')}`]: '%4%5',
        }
      });
    });
  };
  $(init);
})();
