import axios from 'axios';

;(() => {
  const expr = '.js-select-sync-room';

  const init = () => {
    const $elements = $(expr)
    const $parents = $($elements.data('parent-expr'));
    if (!$parents.length) {
      return;
    }

    $elements.each((key, element) => {
      const $element = $(element);
      $element.prop('inSync', false);
    });

    $parents.on('change', { $child: $elements }, onChange);
  };

  const onChange = function (event) {
    const $element = $(event.currentTarget);
    const $child = event.data.$child;
    const childValue = $child.val();

    if ($child.prop('inSync')) {
      return false;
    }

    $element.on('autocomplete.select', async (event, item) => {
      const selectOptions = [$('<option>').attr('value', '').text('選択してください')];
      let responseData;

      if (item == null) { 
        $child.html(selectOptions);
        return false;
      }

      $child.prop('inSync', true);
      $child.prop('disabled', true);

      const dataPath = decodeURI($child.data('refer')).replace('{{value}}', item.value);

      try {
        responseData = (await axios.get(dataPath)).data;
      } catch (error) {
        console.error(error);
      }

      if (responseData == null) return false;

      $.each(responseData, (key, responseDatum) => {
        const $option = $('<option>')
          .attr('value', responseDatum.value)
          .text(responseDatum.text);

        if (responseDatum.value == childValue) {
          $option.prop('selected', true);
        }

        selectOptions.push($option);
      });
      $child.html(selectOptions);

      setTimeout(() => {
        $child
          .prop('disabled', false)
          .prop('inSync', false);
      }, 500);
    });

    return false;
  };

  $(init);

})();